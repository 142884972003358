'use strict';

/**
 * Init scrolling position
 * @param {Selector} container slider container
 * @param {Selector} slider slider
 * @param {Selector} currentItem slider item
 */
function initScrollingPosition(container, slider, currentItem) {
    if (slider.length > 0) {
        slider.scrollLeft(currentItem.position().left - (container.width() / 2) + (currentItem.width() / 2));
    }
}

/**
 * Scroll Button event handler
 * @param {Event} event click event
 * @param {Selector} $slider slider
 */
function handleScrollButton(event, $slider) {
    var $target = $(event.currentTarget);

    var isLeftButton = $target.hasClass('left-btn');
    var currentPosition = $slider.scrollLeft();
    var stepSize = 200;

    if (isLeftButton) {
        $slider.scrollLeft(currentPosition - stepSize);
    } else {
        $slider.scrollLeft(currentPosition + stepSize);
    }
}

/**
* Handles smooth scrolling for a slider element when a navigation button is clicked.
*
* @param {Object} event - The event object triggered by the button click.
* @param {jQuery} $slider - The jQuery object representing the slider element to be scrolled.
*/
function handleSmoothScrollButton(event, $slider) {
    var $target = $(event.currentTarget);
    var isLeftButton = $target.hasClass('left-btn');
    var currentPosition = $slider.scrollLeft();
    var stepSize = currentPosition + (isLeftButton ? -200 : 200);

    $slider.animate({ scrollLeft: stepSize }, 500);
}

/**
 * Scroll Button event handler
 * @param {Event} event click event
 * @param {Selector} $slider Shipping slider
 */
function handleScrollShippingButton(event, $slider) {
    var $target = $(event.currentTarget);
    var isLeftButton = $target.hasClass('left-btn');
    var currentPosition = $slider.scrollLeft();
    var stepSize = 200;
    var scrollPosition = parseInt($slider.attr('data-scroll-position'), 10);
    var scrollLength = parseInt($slider.attr('data-scroll-length'), 10);

    if (isLeftButton && scrollPosition !== 0) {
        $slider.scrollLeft(currentPosition - stepSize);
        $slider.attr('data-scroll-position', (scrollPosition - 1));

        var $rightBtn = $('.shipping-method-block .right-btn');
        if ($rightBtn.hasClass('d-md-none')) {
            $rightBtn.removeClass('d-md-none');
        }

        if (scrollPosition - 1 === 0 && !$target.hasClass('d-md-none')) {
            $target.addClass('d-md-none');
        }
    } else if (!isLeftButton && scrollPosition < scrollLength) {
        $slider.scrollLeft(currentPosition + stepSize);
        $slider.attr('data-scroll-position', (scrollPosition + 1));

        var $leftBtn = $('.shipping-method-block .left-btn');
        if ($leftBtn.hasClass('d-md-none')) {
            $leftBtn.removeClass('d-md-none');
        }

        if (scrollPosition + 1 === scrollLength && !$target.hasClass('d-md-none')) {
            $target.addClass('d-md-none');
        }
    }
}

module.exports = {
    initScrollingPosition: initScrollingPosition,
    handleScrollButton: handleScrollButton,
    handleScrollShippingButton: handleScrollShippingButton,
    handleSmoothScrollButton: handleSmoothScrollButton
};
