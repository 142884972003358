'use strict';

var basicSlider = require('../utils/basicSlider');

/**
* Arranges the quick access menu by adjusting visibility of scroll buttons and initializing scrolling behavior.
* This function checks if the categories container overflows its visible area and, if so, displays scroll buttons
* to allow horizontal navigation. It also sets up the initial scrolling position and binds click events to handle
* smooth scrolling when the scroll buttons are clicked.
* @returns {void} This function does not return a value.
*/
function arrangeQuickAccessMenu() {
    var $categoriesContainer = $('.categories-container');
    var $currentCategory = $('.current-cat');
    var $quickAccessContainer = $('.quick-access-container');

    if ($categoriesContainer.length === 0 || $quickAccessContainer.length === 0) {
        return;
    }

    if ($categoriesContainer[0].scrollWidth > $quickAccessContainer.width()) {
        $quickAccessContainer.find('.scroll-btn').removeClass('d-none');
    } else {
        $quickAccessContainer.find('.scroll-btn').addClass('d-none');
    }

    basicSlider.initScrollingPosition($quickAccessContainer, $categoriesContainer, $currentCategory);
    $quickAccessContainer.on('click', '.scroll-btn', function (event) {
        basicSlider.handleSmoothScrollButton(event, $categoriesContainer);
    });
}
/**
 * create a mutation observer that listens for changes in the quick access menu
*/
function handleQuickAccessChanges() {
    let $gridHeader = $('.grid-header');

    if ($gridHeader.length === 0) {
        return;
    }

    let observer = new MutationObserver(arrangeQuickAccessMenu);
    observer.observe($gridHeader.get(0), { childList: true });

    window.addEventListener('resize', arrangeQuickAccessMenu);
}

/**
 * Init quick access feature
 */
function initQuickAccess() {
    $(document).ready(function () {
        arrangeQuickAccessMenu();
    });

    handleQuickAccessChanges();
}

module.exports = { initQuickAccess };
